import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GitHubIcon from '@material-ui/icons/GitHub';
import AppleIcon from '@material-ui/icons/Apple';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';

const PortfolioItem = ({
  name,
  description,
  links: { github, demo, offline, apple },
  stack,
  screenshots,
  isPrivate,
  inProgress,
}) => {
  const [screenshotOpen, setScreenshotOpen] = useState(false);

  return (
    <div className="portfolio-item morph">
      <div className="portfolio-item__display">
        <span className="portfolio-item__display-name">{name}</span>
        <div
          className={`portfolio-item__display-icons portfolio-item__display-icons-${
            stack.length !== 6 ? 'flex' : 'grid'
          }`}
        >
          {stack.map((icon, index) => (
            <React.Fragment key={index}>{icon}</React.Fragment>
          ))}
        </div>
      </div>
      <div className="portfolio-overlay-wrapper">
        <span className="portfolio-item__description">{description}</span>
        {inProgress ? (
          <div className="portfolio-item__in-progress">
            <span>In development</span>
          </div>
        ) : isPrivate ? (
          <div className="portfolio-item__in-progress">
            <span>Private</span>
          </div>
        ) : (
          <div className="portfolio-item__buttons">
            {github ? (
              <button
                className="morph-button unit"
                type="button"
                onClick={() => window.open(github, '_blank')}
              >
                <GitHubIcon fontSize="large" />
              </button>
            ) : null}
            {demo ? (
              <button
                className="morph-button unit"
                type="button"
                onClick={() => window.open(demo, '_blank')}
              >
                <VisibilityIcon fontSize="large" />
              </button>
            ) : null}
            {apple ? (
              <button
                className="morph-button unit"
                type="button"
                onClick={() => window.open(apple, '_blank')}
              >
                <AppleIcon fontSize="large" />
              </button>
            ) : null}
            {screenshots ? (
              <>
                <button
                  className="morph-button unit"
                  type="button"
                  onClick={() => setScreenshotOpen(true)}
                >
                  <ViewCarouselIcon fontSize="large" />
                </button>
                <ModalGateway>
                  {screenshotOpen ? (
                    <Modal onClose={() => setScreenshotOpen(false)}>
                      <Carousel views={screenshots} />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default PortfolioItem;
