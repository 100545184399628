import React from 'react';
import react from '../images/react.svg';
import mongodb from '../images/mongo.png';
import aws from '../images/aws.svg';
import docker from '../images/docker.svg';
import gcloud from '../images/gcloud.svg';
import graphql from '../images/graphql.svg';
import nodejs from '../images/nodejs.svg';
import python from '../images/python.svg';
import redux from '../images/redux.svg';
import kubernetes from '../images/kubernets.svg';
import tensorflow from '../images/tensorflow.svg';
import reactNative from '../images/react-native.png';
import sockets from '../images/socket-io.svg';
import sql from '../images/mysql.svg';

const projects = [
  {
    name: 'Tileshade',
    description:
      'Game about colours. Made with colours and soft ui design. Soon in App Store/Google Play.',
    links: {
      demo: 'https://expo.io/@nmelon/color',
      apple: 'https://apps.apple.com/lv/app/colory-tiles/id1507448277',
    },
    stack: [
      <img src={reactNative} width="50px" alt="reactNative icon" />,
      <img src={redux} width="45px" alt="redux icon" />,
      <img src={nodejs} width="45px" alt="nodejs icon" />,
      <img src={mongodb} width="60px" alt="mongodb icon" />,
    ],
    screenshots: [
      { src: require('../images/screenshots/colory1.jpg') },
      { src: require('../images/screenshots/colory2.jpg') },
      { src: require('../images/screenshots/colory3.jpg') },
      { src: require('../images/screenshots/colory4.jpg') },
      { src: require('../images/screenshots/colory5.jpg') },
      { src: require('../images/screenshots/colory6.jpg') },
    ],
  },
  {
    name: 'Pushback Digital',
    description:
      'Mobile app to help ease the process of specific legal claim submission.',
    links: {
      github: 'https://github.com/nmelentjevs/reject-it',
      demo: 'https://expo.io/@nmelon/reject-it',
    },
    stack: [
      <img src={reactNative} width="50px" alt="reactNative icon" />,
      <img src={redux} width="45px" alt="redux icon" />,
      <img src={nodejs} width="45px" alt="nodejs icon" />,
      <img src={sockets} width="45px" alt="sockets icon" />,
      <img src={mongodb} width="60px" alt="mongodb icon" />,
    ],
    screenshots: [
      { src: require('../images/screenshots/reject1.jpg') },
      { src: require('../images/screenshots/reject2.jpg') },
      { src: require('../images/screenshots/reject3.jpg') },
      { src: require('../images/screenshots/reject4.jpg') },
      { src: require('../images/screenshots/reject5.jpg') },
    ],
  },
  {
    name: 'Health Digital',
    description:
      'Uber like app for matching health practitioners with clients in need.',
    links: {
      github: 'https://github.com/nmelentjevs/captainfeelsgood-health-client',
      demo: 'https://expo.io/@nmelon/captainfeelsgooddemo',
    },
    stack: [
      <img src={reactNative} width="50px" alt="reactNative icon" />,
      <img src={mongodb} width="60px" alt="mongodb icon" />,
      <img src={nodejs} width="45px" alt="nodejs icon" />,
    ],
  },
  {
    name: 'Mu2al',
    description: 'Tinder like social network currently being developed.',
    links: { github: '', demo: 'https://mu2al.com/' },
    stack: [
      <img src={react} width="50px" alt="react icon" />,
      <img src={reactNative} width="50px" alt="reactNative icon" />,
      <img src={nodejs} width="45px" alt="nodejs icon" />,
      <img src={mongodb} width="60px" alt="mongodb icon" />,
      <img src={sockets} width="45px" alt="sockets icon" />,
      <img src={aws} width="45px" alt="aws icon" />,
    ],
  },
  {
    name: 'Eleve11',
    description:
      'Web app for people interested in numerology and numerologists interested in sharing their predictions.',
    links: { github: '', demo: '' },
    isPrivate: true,
    stack: [
      <img src={react} width="50px" alt="react icon" />,
      <img src={redux} width="45px" alt="redux icon" />,
      <img src={nodejs} width="45px" alt="nodejs icon" />,
      <img src={graphql} width="45px" alt="nodejs icon" />,
      <img src={mongodb} width="60px" alt="mongodb icon" />,
    ],
  },
  {
    name: 'Netcaller',
    description: 'Web scrapper and parser with further data analysis.',
    links: { github: '', demo: '' },
    isPrivate: true,
    stack: [
      <img src={nodejs} width="45px" alt="nodejs icon" />,
      <img src={python} width="45px" alt="python icon" />,
      <img src={sql} width="45px" alt="sql icon" />,
      <img src={tensorflow} width="40px" alt="tensorflow icon" />,
      <img src={gcloud} width="45px" alt="gcloud icon" />,
    ],
  },
  {
    name: 'Betmelon',
    description:
      'Social network for keeping and sharing football predictions and bet results.',
    links: {
      github: 'https://github.com/nmelentjevs/betmelon',
      demo: false,
      offline: true,
    },
    stack: [
      <img src={react} width="50px" alt="react icon" />,
      <img src={redux} width="45px" alt="redux icon" />,
      <img src={nodejs} width="45px" alt="nodejs icon" />,
      <img src={mongodb} width="60px" alt="mongodb icon" />,
      <img src={docker} width="50px" alt="docker icon" />,
      <img src={kubernetes} width="50px" alt="kubernetes icon" />,
    ],
  },

  {
    name: 'Cherry Sports',
    description:
      "Football game predictions based on previous matches and season's data.",
    links: { github: 'https://github.com/nmelentjevs/Melon-Sports', demo: '' },
    stack: [
      <img src={react} width="50px" alt="react icon" />,
      <img src={tensorflow} width="40px" alt="tensorflow icon" />,
    ],
  },
];

export default projects;
