import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Welcome from './components/Welcome';

const routing = (
  <Router>
    <Route exact path="/" component={Welcome} />
  </Router>
);

const App = () => {
  return routing;
};

export default App;
