import React, { useState } from 'react';
import { Element } from 'react-scroll';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import WorkIcon from '@material-ui/icons/Work';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PortfolioItem from './PortfolioItem';
import EmailIcon from '@material-ui/icons/Email';
import projects from '../constants/projects';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import axios from 'axios';
import PulseLoader from 'react-spinners/PulseLoader';

const Welcome = () => {
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleSendEmail = async () => {
    if (!sending) {
      if (name && subject && message) {
        setEmailError(false);
        setSending(true);
        await axios
          .post(
            'https://cors-anywhere.herokuapp.com/https://captainfeelgood-rejectit-backend.kingusha2333.now.sh/claims/contact',
            {
              name,
              subject,
              message,
            }
          )
          .then(() => {
            setSending(false);
            setSent(true);
            setName('');
            setSubject('');
            setMessage('');
          })
          .catch(console.log);
      } else {
        setEmailError(true);
      }
    }
  };

  return (
    <div className="main-container">
      <div id="home" className="banner">
        <div className="container">
          <div className="divider"></div>
          <div id="banner-top" className="banner-top">
            <h1 className="name-heading name-primary">Mr. Melentjevs</h1>
            <h2 className="name-heading title-sub">THE DEVELOPER</h2>
          </div>
          <div className="segment">
            <button
              className="morph-button unit"
              type="button"
              onClick={() =>
                window.open('https://github.com/nmelentjevs', '_blank')
              }
            >
              <GitHubIcon fontSize="large" />
            </button>
            <button
              className="morph-button unit"
              type="button"
              onClick={() =>
                window.open(
                  'https://www.linkedin.com/in/nikita-melentjevs',
                  '_blank'
                )
              }
            >
              <LinkedInIcon fontSize="large" />
            </button>
            <button
              className="morph-button unit"
              type="button"
              onClick={() =>
                window.open(
                  'https://www.upwork.com/freelancers/~01328a68b2d9c59b0b',
                  '_blank'
                )
              }
            >
              <WorkIcon fontSize="large" />
            </button>
          </div>
        </div>
      </div>

      <Element name="work" className="element">
        <div id="work" className="work">
          <div className="container">
            <div className="divider"></div>
            <h3 className="name-heading">PROJECTS</h3>
            <p>Explore my works</p>

            <div className="project">
              <div id="workgrid">
                {projects.map((project, index) => (
                  <PortfolioItem key={index} {...project} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Element>

      <Element name="timeline" className="element">
        <div className="container">
          <div className="divider"></div>

          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                padding: '1.75rem',
                borderRadius: '2rem',
                backgroundColor: '#ebecf0',
                boxShadow: '4px 4px 8px #babecc, -4px -4px 8px #fff',
                textAlign: 'center',
              }}
              contentArrowStyle={{
                display: 'none',
              }}
              date="October 2018 - present"
              iconStyle={{
                backgroundColor: '#ebecf0',
                boxShadow: '3px 3px 8px #babecc, -3px -3px 8px #fff',
              }}
              icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title">
                Full Stack Developer
              </h3>

              <p className="timeline-paragraph">EyeCu.Digital</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="December 2019 - Present"
              contentStyle={{
                padding: '1.75rem',
                borderRadius: '2rem',
                backgroundColor: '#ebecf0',
                boxShadow: '4px 4px 8px #babecc, -4px -4px 8px #fff',
                textAlign: 'center',
              }}
              contentArrowStyle={{
                display: 'none',
              }}
              iconStyle={{
                backgroundColor: '#ebecf0',
                boxShadow: '3px 3px 8px #babecc, -3px -3px 8px #fff',
              }}
              icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title">
                Co-Founder/CTO
              </h3>

              <p className="timeline-paragraph">Captain FeelGood</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="July 2018 - February 2019"
              contentStyle={{
                padding: '1.75rem',
                borderRadius: '2rem',
                backgroundColor: '#ebecf0',
                boxShadow: '4px 4px 8px #babecc, -4px -4px 8px #fff',
                textAlign: 'center',
              }}
              contentArrowStyle={{
                display: 'none',
              }}
              iconStyle={{
                backgroundColor: '#ebecf0',
                boxShadow: '3px 3px 8px #babecc, -3px -3px 8px #fff',
              }}
              icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title">
                DevOps Engineer
              </h3>
              <p className="timeline-paragraph">n-fuse GmbH</p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </Element>

      <Element name="about" className="element">
        <div id="about" className="about">
          <div className="container">
            <div className="divider"></div>
            <h3 className="name-heading">ME</h3>
            <p>
              ˜If your presence doesn't make an impact, your absence won't make
              a difference.˜
            </p>
            <div className="about-info">
              <div
                className="col-md-12 about-right"
                style={{ textAlign: 'center' }}
              >
                <p>
                  I am familiar and have built some projects with most of the
                  technologies list such as React/React Native, Redux, NodeJS,
                  Express, GraphQL, Git, ES6, etc.. as well as deploying those
                  apps to AWS or Google Cloud using Docker and Kubernetes and
                  some CI/CD tools. I am always very eager to learn and try new
                  technologies as well as excel more in what I have already
                  worked with.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Element>

      <Element name="contact" className="element">
        <div id="contact" className="contact">
          <div className="container">
            <div className="divider" />
            <div className="segment">
              <h3>CONTACT</h3>
            </div>
            <p>
              Mainly for work enquiries, but any feedback is highly appreciated.
            </p>
            {emailError ? <h5>Please fill all the fields!</h5> : null}
            {sent ? (
              <h5 style={{ fontSize: '18px' }}>
                Thank you for your message, I will respond as soon as possible!
              </h5>
            ) : null}
            <form>
              <label>
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
              <label>
                <input
                  type="text"
                  placeholder="Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </label>
              <label>
                <textarea
                  type="text"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </label>
              <button
                className="morph-button unit wide"
                type="button"
                onClick={handleSendEmail}
                disabled={sending}
              >
                {!sending ? (
                  <EmailIcon fontSize="large" />
                ) : (
                  <PulseLoader size={10} color="#babecc" />
                )}
              </button>
            </form>

            <div className="divider"></div>
            <div className="copyrights">
              <p>
                Copyright &copy; 2020. Made with <FavoriteBorderIcon /> by
                <a
                  href="https://www.linkedin.com/in/nikita-melentjevs/"
                  style={{ textDecoration: 'none' }}
                >
                  {' '}
                  nmelentjevs.
                </a>
              </p>
            </div>
          </div>
        </div>
      </Element>
    </div>
  );
};

export default Welcome;
